if ($('.defaultNav').length) {
	var scroll_init = 0;
	$(window).on('scroll', function(event) {
		var scroll_body = $(this).scrollTop();
		var nav = $('.defaultNav');

		/*
			Aplica a classe que contem o estilo
			primário para o nav quando a pagina
			executar o evento scroll
		*/
		if (scroll_body > 99) {
			nav.addClass("defaultNav--scroll");
		} else {
			nav.removeClass("defaultNav--scroll");
		}

		/*
			Quando a página rolar 600px pra baixo o nav
			desaparece e recebe a classe "middle" para
			identificar o estado.
		*/
		if (scroll_body > 600) {
			nav.addClass("defaultNav--hide");
			nav.addClass("defaultNav--middle");
		} else {
			nav.removeClass("defaultNav--hide"); 
			nav.removeClass("defaultNav--middle");
		}

		/*
			verifica se o scroll da página foi
			pra cima, caso sim faz o menu aparecer.
		*/
		if (scroll_body < scroll_init) {
			nav.removeClass("defaultNav--hide");
		}
		
		// var referencia
		scroll_init = scroll_body;
	});

	// open/close hamburguer menu
	$('.c-hamburguer').on('click', function() {
		$(this).toggleClass('is-active');
		$('.defaultNav__contentNav').toggleClass('is-active');
		$('.defaultNav__maskMenu').toggleClass('is-active');
		$('.u-maxRow').toggleClass('nav__is-active');
		$('body').toggleClass('u-overflowBlocked');
	});

	// close hamburguer menu
	$('.defaultNav__maskMenu').on('click', function() {
		$('.c-hamburguer').removeClass('is-active');
		$('.defaultNav__contentNav').removeClass('is-active');
		$('.defaultNav__maskMenu').removeClass('is-active');
		$('.u-maxRow').removeClass('nav__is-active');	
		$('body').removeClass('u-overflowBlocked');
	});
}

if($('.l-footer').length) {
	var userFeed = new Instafeed({
		get: 'user',
		userId: '11856800213',
		accessToken: '11856800213.1677ed0.a1e8c893fa464fc2a92c685eeedf38e9',
		limit: 4,
		resolution: 'low_resolution',
		template: '<li><a href="{{link}}" target="_blank"><img src="{{image}}"/></a></li>',
	});
	userFeed.run();
	$(window).on('load', function(event) {
		if ($("#instafeed li").length){
			var larguraThumbs = $('#instafeed li').width();
			$('#instafeed li').css('height', larguraThumbs);
		}
	});
}

// link whats app - menu nav
var whatsAppLink = $('.js-linkWhatsApp');
if (is.iphone() || is.androidPhone() || is.windowsPhone() || is.blackberry()) {
	whatsAppLink.attr('href', whatsAppLink.attr('data-link-smartphone'));
} else {
	whatsAppLink.attr('href', whatsAppLink.attr('data-link-geral'));
}